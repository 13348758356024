import { bool, cleanEnv, str, url } from "envalid";

const nextEnv = {
  NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV,
  NEXT_PUBLIC_VERCEL_URL: process.env.NEXT_PUBLIC_VERCEL_URL,
  NEXT_PUBLIC_BACKEND_API_URL: process.env.NEXT_PUBLIC_BACKEND_API_URL,
  NEXT_PUBLIC_BACKEND_GRPC_ROOT_PATH:
    process.env.NEXT_PUBLIC_BACKEND_GRPC_ROOT_PATH,
  NEXT_PUBLIC_USE_EMULATOR: process.env.NEXT_PUBLIC_USE_EMULATOR,
  NEXT_PUBLIC_SPWN_API_URL: process.env.NEXT_PUBLIC_SPWN_API_URL,
  NEXT_PUBLIC_THEOPLAYER_LICENSE: process.env.NEXT_PUBLIC_THEOPLAYER_LICENSE,
  NEXT_PUBLIC_THEOPLAYER_LIBRARY_LOCATION:
    process.env.NEXT_PUBLIC_THEOPLAYER_LIBRARY_LOCATION,
  NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN:
    process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  NEXT_PUBLIC_FIREBASE_DATABASE_URL:
    process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET:
    process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID:
    process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  NEXT_PUBLIC_STORAGE_URL: process.env.NEXT_PUBLIC_STORAGE_URL,
  NEXT_PUBLIC_ACCOUNTS_APP_URL: process.env.NEXT_PUBLIC_ACCOUNTS_APP_URL,
  NEXT_PUBLIC_APP_ROOT_URL: process.env.NEXT_PUBLIC_APP_ROOT_URL,
  NODE_ENV: process.env.NODE_ENV,
};

export const envConfig = cleanEnv(nextEnv, {
  NEXT_PUBLIC_VERCEL_ENV: str({
    desc: "Vercel環境",
    default: "development",
  }),
  NEXT_PUBLIC_VERCEL_URL: str({
    desc: "VercelのURL",
    default: "crew.spwn.jp",
  }),
  NEXT_PUBLIC_BACKEND_API_URL: url({
    desc: "Backend API URL",
  }),
  NEXT_PUBLIC_BACKEND_GRPC_ROOT_PATH: url({
    desc: "Backend gRPC Root Path",
  }),
  NEXT_PUBLIC_USE_EMULATOR: bool({
    desc: "Use Emulator",
    default: false,
  }),
  NEXT_PUBLIC_SPWN_API_URL: url({
    desc: "SPWN API URL",
    default: "",
  }),
  NEXT_PUBLIC_THEOPLAYER_LICENSE: str({
    desc: "THEOPLAYER License V8",
  }),
  NEXT_PUBLIC_THEOPLAYER_LIBRARY_LOCATION: str({
    desc: "THEOPLAYER Library Location V8",
  }),
  NEXT_PUBLIC_FIREBASE_API_KEY: str({
    desc: "Firebase API Key",
    default: "",
  }),
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: str({
    desc: "Firebase Auth Domain",
    default: "",
  }),
  NEXT_PUBLIC_FIREBASE_DATABASE_URL: str({
    desc: "Firebase Database URL",
    default: "",
  }),
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: str({
    desc: "Firebase Project ID",
    default: "",
  }),
  NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: str({
    desc: "Firebase Storage Bucket",
    default: "",
  }),
  NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: str({
    desc: "Firebase Messaging Sender ID",
    default: "",
  }),
  NEXT_PUBLIC_STORAGE_URL: url({
    desc: "Storage URL",
    default: "",
  }),
  // NOTE: vercel env pullによって取得するので、ローカルでも常に値がある
  NEXT_PUBLIC_ACCOUNTS_APP_URL: url({
    desc: "Accounts App URL",
    default: "",
  }),
  NEXT_PUBLIC_APP_ROOT_URL: url({
    desc: "App Root URL",
    default: "",
  }),
  // TODO: 使っている場所駆逐したい
  NODE_ENV: str({
    desc: "Node.js環境",
  }),
});
