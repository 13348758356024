import Router, { useRouter } from "next/router";
import Script from "next/script";
import NProgress from "nprogress";
import { FC, useEffect } from "react";
import TagManager from "react-gtm-module";

import { appWithTranslation } from "@spwn-portal/i18n-utils/next";
import { ChakraProvider } from "@spwn-portal/ui/chakra";

import { gtmTagConfig } from "../constants/gtmTag";
import { AuthContextProvider } from "../contexts/auth";
import { ConfigContextProvider } from "../contexts/config";
import { FirebaseContextProvider } from "../contexts/firebase";
import { SWRContextProvider } from "../contexts/swr";
import { useRouterHistory } from "../hooks/useRouterHistory";
import { theme } from "../ui/theme";

import type { AppProps } from "next/app";

import "nprogress/nprogress.css";

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  useRouterHistory();
  /** プログレスバーを表示 */
  NProgress.configure({ showSpinner: false });
  Router.events.on("routeChangeStart", () => NProgress.start());
  Router.events.on("routeChangeComplete", () => NProgress.done());
  Router.events.on("routeChangeError", () => NProgress.done());

  /** Googleタグマネージャー */
  const router = useRouter();
  useEffect(() => {
    TagManager.initialize({ gtmId: gtmTagConfig.NEXT_PUBLIC_GTM_ID });
    router.events.on("routeChangeComplete", () => {
      TagManager.dataLayer({
        dataLayer: {
          pagePath: window.location.pathname,
          pageTitle: document.title,
        },
      });
    });
  }, [router]);

  return (
    // <React.Suspense
    //   fallback={
    //     <div className="flex items-center justify-center w-screen h-screen">
    //       <Spinner size="xl" />
    //     </div>
    //   }
    // >
    <ConfigContextProvider>
      {/* https://iframely.com/docs/omit-script */}
      <Script async src={"//cdn.iframe.ly/embed.js"} />
      <FirebaseContextProvider>
        <AuthContextProvider>
          <SWRContextProvider>
            <ChakraProvider theme={theme}>
              <Component {...pageProps} />
            </ChakraProvider>
          </SWRContextProvider>
        </AuthContextProvider>
      </FirebaseContextProvider>
    </ConfigContextProvider>
    // </React.Suspense>
  );
};

export default appWithTranslation(MyApp);
