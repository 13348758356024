import React, { createContext, FC, useContext } from "react";

import { envConfig } from "../constants/env";

const vercelEnvironment = ["production", "preview", "development"] as const;
type VercelEnvironment = typeof vercelEnvironment[number];
const isVercelEnvironment = (env: unknown): env is VercelEnvironment => {
  return vercelEnvironment.some((e) => e === env);
};

type Config = Readonly<{
  vercel: Vercel;
  firebase: Firebase;
  app: App;
  emulators: Emulators | undefined;
}>;

type Emulators = Readonly<{
  [key in "functions" | "auth"]: {
    host: string;
    port: number;
  };
}>;

type Vercel = Readonly<{
  environment: VercelEnvironment;
}>;

type Firebase = Readonly<{
  apiKey: string;
  authDomain: string;
  databaseUrl: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
}>;

type App = Readonly<{
  publicStorageUrl: string;
  /**
   * ログイン認証用のURL
   * - prod: https://accounts.spwn.jp
   * - dev: https://accounts-dev.spwn.jp
   */
  accountsAppUrl: string;
  /**
   * fanclubのURL
   * - prod: https://crew.spwn.jp
   * - dev: https://crew-dev.spwn.jp
   */
  fanclubUrl: string;
}>;

/**
 * cmsからそのまま持ってきてる
 * - [Vercel env](https://vercel.com/docs/concepts/projects/environment-variables)
 */
const defaultConfig: Config = {
  vercel: {
    environment: isVercelEnvironment(envConfig.NEXT_PUBLIC_VERCEL_ENV)
      ? envConfig.NEXT_PUBLIC_VERCEL_ENV
      : "development",
  },
  firebase: {
    apiKey: envConfig.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: envConfig.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    databaseUrl: envConfig.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
    projectId: envConfig.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: envConfig.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: envConfig.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  },
  app: {
    publicStorageUrl: envConfig.NEXT_PUBLIC_STORAGE_URL,
    accountsAppUrl: envConfig.NEXT_PUBLIC_ACCOUNTS_APP_URL,
    fanclubUrl: envConfig.NEXT_PUBLIC_APP_ROOT_URL,
  },
  emulators: envConfig.NEXT_PUBLIC_USE_EMULATOR
    ? {
        functions: {
          host: "localhost",
          // FIXME backendのportと合わせる
          port: 5001,
        },
        auth: {
          host: "localhost",
          port: 9099,
        },
      }
    : undefined,
};

/**
 * Stroybook やテストで必要な値のみ埋めるための Config
 */
export const mockConfig: Config = {
  vercel: {
    environment: "development",
  },
  firebase: {
    apiKey: "mock-api-key",
    authDomain: "mock-auth-domain",
    databaseUrl: "mock-database-url",
    projectId: "mock-project-id",
    storageBucket: "mock-storage-bucket",
    messagingSenderId: "mock-message-sender-id",
  },
  app: {
    publicStorageUrl: "mock-public-storage-rul",
    accountsAppUrl: "https://accounts-dev.spwn.jp",
    fanclubUrl: "https://crew-dev.spwn.jp/",
  },
  emulators: undefined,
};

export const ConfigContext = createContext<
  Readonly<{
    config: Config;
  }>
>({ config: defaultConfig });

const useConfigContext = (): { config: Config } => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error(`Must be used within a ConfigContextProvider`);
  }
  return context;
};

export const ConfigContextProvider: FC<{
  vercel?: Vercel;
  firebase?: Firebase;
  children?: React.ReactNode;
}> = ({ children, ...values }) => {
  return (
    <ConfigContext.Provider value={{ config: { ...defaultConfig, ...values } }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = (): { config: Config } => {
  const { config } = useConfigContext();
  return {
    config,
  };
};
